import React from "react";

function Img() {
  return (
    <>
      {/* Button trigger modal */}
      <button
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        Launch Fullscreen Modal
      </button>

      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content text-bg-light">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Fullscreen Modal
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              This is a fullscreen modal using only Bootstrap.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Img;
