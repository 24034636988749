import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import flibs from "../Images/56p.jpeg";

const Popup = () => {
  useEffect(() => {
    //3 seconds ON
    const showPopup = setTimeout(() => {
      document.getElementById("popup-container").style.display = "block";
    }, 6000);

    //7 seconds OFF
    const closePopup = setTimeout(() => {
      document.getElementById("popup-container").style.display = "none";
    }, 12000);

    return () => {
      clearTimeout(showPopup);
      clearTimeout(closePopup);
    };
  }, []);

  return (
    <div id="popup-container">
      <span
        id="close-btn"
        onClick={() => {
          document.getElementById("popup-container").style.display = "none";
        }}
      >
        <i className="fa-solid fa-circle-xmark text-secondary"></i>
      </span>

      <Link to={`/news-details/flibs`}>
        <img src={flibs} alt="hcbyachts@AYG" className="img-fluid" />
      </Link>

      {/* <div
        class="card m-0 border-0 bg-transparent"
        style={{ maxWidth: "840px" }}
      >
        <Link to={`/hcb`}>
          <div class="row g-0 p-2">
            <div class="col-lg-6">
              <img
                src="https://aimbaseimage.blob.core.windows.net/hcbyachts/Images/Boat_Ad_Template-JR.png"
                class="img-fluid rounded-start"
                alt="..."
              />
            </div>
            <div class="col-lg-6">
              <div class="card-body">
                <small class="text-primary">
                  <span>American Yacht Group</span>
                </small>
                <h5 class="card-title">
                  BROKERAGE SPOTLIGHT 2022 HCB 42’ LUJO
                </h5>

                <p class="card-text ">
                  Highlighted features: Upgraded Garmin Electronics, Upgraded JL
                  Audio Sound System, Carbon fiber Edson Steering wheel, Gem lux
                  18’ Carbon fiber outriggers, and Dolphin grey upholstery.
                </p>
                <p className=" text-white btn btn-sm custom-contact-btn">
                  Explore NOW{"  "}
                  <i class="fa-solid fa-angles-right"></i>
                </p>
              </div>
            </div>
          </div>
        </Link>
      </div> */}
    </div>
  );
};

export default Popup;
